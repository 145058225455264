import React, {useEffect, useState} from 'react'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import useSiteMetadata from '../util/useSiteMetadata'
import classNames from 'classnames'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
// import SoftSkills from '../components/CoursesSoftskills'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import * as buttonStyles from '../styles/button.module.css'
import Card from 'react-bootstrap/Card'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import LearnerDashboard from '../components/LearnerDashboard'

export default function Learners() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch
  const siteMeta = useSiteMetadata()


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])

  return (
    <main>
      <title>Learners</title>
      <NavBar global={global} dispatch={dispatch} />
      <div className='page-heading'>
        <Container>
          <h1>Learners</h1>
        </Container>
      </div>
      <Container className='py-3'>
        <Tabs
          defaultActiveKey='dashboard'
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          {/* <Tab eventKey='dashboard' title={<h2 className='fs-3'>Dashboard</h2>}>
            <Row>
              <LearnerDashboard
                global={global}
                dispatch={dispatch}
                siteMeta={siteMeta}
              />
            </Row>
          </Tab> */}
          <Tab eventKey='ceoh' title={<h2 className='fs-3'>CE-Oh! Courses</h2>}>
            <h2>CE-Oh!</h2>
            <Row xs={1} sm={1} md={2} lg={3} xxl={4} className='g-4'>
              <Col>
                <Card>
                  <Card.Img alt='' variant='top' src='../images/course-ceoh/pre-introduction.webp' />
                  <Card.Body>
                    <Card.Title>
                      00: Pre-Introduction
                    </Card.Title>
                    <Card.Text>
                      This course will provide you with an overview of the VERA platform, completing courses, and troubleshooting common concerns.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.paradisolms.net/course/view.php?id=260'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-01-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-01-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-01-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    {/* <hr /><p className="h6">Additional Resources</p>
                    <Button
                      type="button"
                      target='_blank'
                      href='../modules/module-01-business-model-canvas-workbook.pdf'
                      size="sm"
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Business Model Canvas Workbook
                    </Button>
                    <Button
                      type="button"
                      target='_blank'
                      href='../modules/module-01-blake-mycoskie-biography.pdf'
                      size="sm"
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Blake Mycoskie Biography
                    </Button>
                    <Button
                      type="button"
                      target='_blank'
                      href='../modules/module-01-sba-faq.pdf'
                      size="sm"
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      SBA FAQ
                    </Button> */}
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='' variant='top' src='../images/course-ceoh/introduction.png' />
                  <Card.Body>
                    <Card.Title>
                      01: Introduction to Entrepreneurship
                    </Card.Title>
                    <Card.Text>
                      Learn about and compare real entrepreneurs &amp;
                      innovators; and learn how products &amp; services are
                      developed in response to customer needs.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=87'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-01-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-01-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-01-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>

                    </ButtonGroup>
                    <hr /><p className='h6'>Additional Resources</p>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-01-business-model-canvas-workbook.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Business Model Canvas Workbook
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-01-blake-mycoskie-biography.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Blake Mycoskie Biography
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-01-sba-faq.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      SBA FAQ
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Problem Solving and Social Responsibility' variant='top' src='../images/course-ceoh/problem-solving.webp' />
                  <Card.Body>
                    <Card.Title>
                      02: Problem Solving and Social Responsibility
                    </Card.Title>
                    <Card.Text>
                      Identify ways to solve social problems; learn the purpose
                      of mission and vision statements; and provide examples of
                      socially responsible business practices.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=89'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-02-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-02-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-02-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Generating Business Ideas and Creative Thinking' variant='top' src='../images/course-ceoh/business-ideas-creative-thinking.webp' />
                  <Card.Body>
                    <Card.Title>
                      03: Generating Business Ideas and Creative Thinking
                    </Card.Title>
                    <Card.Text>
                      Learn about the entrepreneurial mindset; identify creative
                      business ideation; engage in creative thinking; and learn
                      the purpose of the business model canvas.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=90'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-03-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-03-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-03-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr /><p className='h6'>Additional Resources</p>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-03-brainstorming-and-mind-mapping.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Brainstorming and Mind Mapping
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Market Research' variant='top' src='../images/course-ceoh/market-research.webp' />
                  <Card.Body>
                    <Card.Title>04: Market Research</Card.Title>
                    <Card.Text>
                      Learn the importance of planning and using market research
                      methodologies; the concept of Lean Startup Methodology;
                      explore market research resources; understand primary and
                      secondary data; refine business ideas based on research
                      and applying research cycles.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=91'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-04-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-04-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-04-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr /><p className='h6'>Additional Resources</p>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-04-useful-links.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Useful Links
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Economics' variant='top' src='../images/course-ceoh/economics.webp' />
                  <Card.Body>
                    <Card.Title>05: Economics</Card.Title>
                    <Card.Text>
                      Focusing on the U.S. economy, learn about types of
                      economic systems; identify the role of the entrepreneur in
                      the economy; learn about suppliers and consumers and their
                      relationship; and learn the concept of supply and demand.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=92'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-05-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-05-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-05-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Competition' variant='top' src='../images/course-ceoh/competition.webp' />
                  <Card.Body>
                    <Card.Title>06: Competition</Card.Title>
                    <Card.Text>
                      Understand different types of competition and how it
                      impacts pricing; identify different types of competitors;
                      explore ways for businesses to remain competitive; and
                      learn how to gather competitive intelligence and create
                      competitive advantages.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=98'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-06-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-06-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-06-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr/><p className='h6'>Additional Resources</p>

                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-06-sample-competitive-matrix.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Sample Competitive Matrix
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-06-eou-sample.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      EOU sample
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
          
                  <Card.Img alt='Value Proposition' variant='top' src='../images/course-ceoh/value-propositions.webp' />
                  <Card.Body>
                    <Card.Title>07: Value Proposition</Card.Title>
                    <Card.Text>
                      Identify and describe value proposition; differentiate
                      between benefits and features; and identify ways to
                      promote value.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=99'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-07-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-07-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-07-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr/><p className='h6'>Additional Resources</p>

                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-07-constructing-a-problem-statement.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Constructing a Problem Statement
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Business Expenses' variant='top' src='../images/course-ceoh/business-expenses.webp' />
                  <Card.Body>
                    <Card.Title>08: Business Expenses</Card.Title>
                    <Card.Text>
                      Examine common business expense categories; understand
                      contribution margins; determine profit and loss; apply
                      break-even analysis; and learn ways to manage ongoing
                      business expenses.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=100'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-08-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-08-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-08-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr/><p className='h6'>Additional Resources</p>

                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-08-sample-cash-flow-statement.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Sample Cash Flow Statement
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-08-sample-contribution-margin-worksheets.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Sample Contribution Margin Worksheets
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Revenue Streams'
                    variant='top' src='../images/course-ceoh/revenue-streams.webp' />
                  <Card.Body>
                    <Card.Title>09: Revenue Streams</Card.Title>
                    <Card.Text>
                      Identify potential revenue streams; understand sales
                      forecasting; and learn the parts and functions of income
                      statements.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=101'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-09-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-09-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-09-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr/>
                    <p className='h6'>Additional Resources</p>

                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-09-income-statement.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Income Statement
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-09-household-budgeting-worksheet.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Household Budgeting Worksheet
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-09-swot-analysis-worksheet.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      SWOT Analysis Worksheet
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-09-Simple-Balance-Sheet.xlsx'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Simple Balance Sheet
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-09-sales-forecasting-tools.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Sales Forecasting Tools
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-09-sample-profit-and-loss-template.xlsx'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Sample Profit and Loss Template
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Financing Your Business' variant='top' src='../images/course-ceoh/financing-business.webp' />
                  <Card.Body>
                    <Card.Title>10: Financing Your Business</Card.Title>
                    <Card.Text>
                      Estimate the start-up investment needed; learn about ROI
                      and how to calculate it; and find ways to obtain
                      investments.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=102'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-10-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-10-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-10-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr/>
                    <p className='h6'>Additional Resources</p>

                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-10-contracts-and-agreements-best-practices.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Contracts and Agreements Best Practices
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-10-venture-capital-list.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Venture Capital List
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>
              <h2 className='visually-hidden'>Title for screen readers</h2>
              <a className='visually-hidden-focusable' href='#content'>Skip to main content</a>
              <div className='visually-hidden-focusable'>A container with a <a href='#'>focusable element</a>.</div>
              <Col>
                <Card>
                  <Card.Img alt='Business Financials' variant='top' src='../images/course-ceoh/business-financials.webp' />
                  <Card.Body>
                    <Card.Title>11: Business Financials</Card.Title>
                    <Card.Text>
                      Learn how to utilize and analyze income statements; build
                      a balance sheet; identify the functions of various
                      financial statements; and learn how to calculate financial
                      ratios.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=130'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-11-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-11-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-11-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr/>
                    <p className='h6'>Additional Resources</p>

                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-11-balance-sheet.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Balance Sheet
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-11-sample-cash-flow-statement.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Sample Cash Flow Statement
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-11-same-size-balance-sheet.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Same Size Balance Sheet
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Managing your Business' variant='top' src='../images/course-ceoh/managing-your-business.webp' />
                  <Card.Body>
                    <Card.Title>12: Managing your Business</Card.Title>
                    <Card.Text>
                      Identify management functions and leaderships styles; create organizational structures; learn planning strategies; and learn how to create a healthy business environment.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=131'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-12-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-12-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-12-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>
                    <hr/>
                    <p className='h6'>Additional Resources</p>

                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-12-sample-organizational-charts.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Sample Organizational Charts
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Growth and Exit Plans' variant='top' src='../images/course-ceoh/growth-and-exit-plans.webp' />
                  <Card.Body>
                    <Card.Title>13: Growth and Exit Plans</Card.Title>
                    <Card.Text>
                      Understand business growth and product life cycles; learn
                      how to value a business; identify possible exit
                      strategies; and understand investments and how money
                      grows.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=132'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-13-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-13-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-13-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Legal Structures' variant='top' src='../images/course-ceoh/legal-structures.webp' />
                  <Card.Body>
                    <Card.Title>14: Legal Structures</Card.Title>
                    <Card.Text>
                      Identify different types of legal structures and their
                      strengths and weaknesses.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=133'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-14-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-14-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-14-terms-and-definitions.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Terms & Definitions
                      </Button>
                    </ButtonGroup>

                  </Card.Footer>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Img alt='Constructing a Business Plan' variant='top' src='../images/course-ceoh/constructing-a-business-plan.webp' />
                  <Card.Body>
                    <Card.Title>15: Constructing a Business Plan</Card.Title>
                    <Card.Text>
                      Apply what has been learned to create a business model
                      canvas; create an elevator pitch; and learn how to build a
                      pitch deck.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=134'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-15-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-15-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>

                    </ButtonGroup>
                    <hr/>
                    <p className='h6'>Additional Resources</p>

                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-15-elevator-speech-template-and-sample.pdf'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      Elevator Speech Template and Sample
                    </Button>
                    <Button
                      type='button'
                      target='_blank'
                      href='../modules/module-15-PPT-Presentation-Examples-TOMS.pptx'
                      size='sm'
                      className={classNames(buttonStyles.buttonSecondary)}
                    >
                      PPT Presentation Examples – TOMS
                    </Button>

                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey='soft' title={<h2 className='fs-3'>Soft Skills Courses</h2>}>
            <h2>Soft Skills Courses</h2>
            <Row xs={1} sm={1} md={2} lg={3} xxl={4} className='g-4'>
              <Col>
       
                <Card>
                  <Card.Img alt='Conflict Resolution' variant='top' src='../images/course-soft-skills/conflict-resolution.webp' />
                  <Card.Body>
                    <Card.Title>Conflict Resolution</Card.Title>
                    <Card.Text>
                      The Conflict Resolution course reviews the “what,” the
                      “why,” and the “how” of conflict resolution in the
                      workplace.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=228'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-conflict-resolution-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-conflict-resolution-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-conflict-resolution-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='Effective Communication' variant='top' src='../images/course-soft-skills/effective-communication.webp' />
                  <Card.Body>
                    <Card.Title>Effective Communication</Card.Title>
                    <Card.Text>
                      Effective communication takes hard work and practice. This
                      course is the first step in learning how to communicate
                      effectively in the workplace.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=209'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-effective-communication-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-effective-communication-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-effective-communication-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='Financial Literacy' variant='top' src='../images/course-soft-skills/financial-literacy.webp' />
                  <Card.Body>
                    <Card.Title>Financial Literacy</Card.Title>
                    <Card.Text>
                      Outline strategies of good money management and budgeting skills. Examples will be detailed in each module as a reference and a series of worksheets and planners are included.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=210'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup variant='secondary' size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-financial-literacy-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-financial-literacy-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-financial-literacy-script.pdf'
                        variant='secondary'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='Get Empowered' variant='top' src='../images/course-soft-skills/get-empowered.webp' />
                  <Card.Body>
                    <Card.Title>Get Empowered</Card.Title>
                    <Card.Text>
                      Learn what it means to get empowered, the meaning of SMART, and how you can incorporate it to help with setting goals and moving towards the things you want to accomplish.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=230'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-get-empowered-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-get-empowered-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-get-empowered-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='Going Social' variant='top' src='../images/course-soft-skills/going-social.webp' />
                  <Card.Body>
                    <Card.Title>Going Social</Card.Title>
                    <Card.Text>
                      From marketing yourself properly to networking, the Going
                      Social course will cover how to use social media in your
                      job search.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=215'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-going-social-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-going-social-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-going-social-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='Optimizing Your Online Job Applications' variant='top' src='../images/course-soft-skills/optimizing-applications.webp' />
                  <Card.Body>
                    <Card.Title>
                      Optimizing Your Online Job Applications
                    </Card.Title>
                    <Card.Text>
                      Learn the advantages of applying for jobs online, how to prepare, then apply the skills learned to complete online job applications.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=211'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup variant='secondary' size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-optimizing-your-online-job-applications-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-optimizing-your-online-job-applications-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-optimizing-your-online-job-applications-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='Skills Identification' variant='top' src='../images/course-soft-skills/skills-identification.webp' />
                  <Card.Body>
                    <Card.Title>Skills Identification</Card.Title>
                    <Card.Text>
                      The Skills Identification course includes nine modules and
                      will allow you to assess yourself based on personality and
                      skills.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=228'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-skills-identification-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-skills-identification-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-skills-identification-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='The Art of the Interview' variant='top' src='../images/course-soft-skills/art-of-the-interview.webp' />
                  <Card.Body>
                    <Card.Title>The Art of the Interview</Card.Title>
                    <Card.Text>
                      This course has a total of six modules designed to cover
                      key information and tips about interviews and interviewing
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=212'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-art-of-the-interview-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-art-of-the-interview-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-art-of-the-interview-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='The Dos, Donts and Whys of Resume Writing' variant='top' src='../images/course-soft-skills/resume.webp' />
                  <Card.Body>
                    <Card.Title>
                      The Do's, Don't's & Why's of Resume Writing
                    </Card.Title>
                    <Card.Text>
                      We will cover the anatomy of a resume, as well as best practices and tools to help you craft each part.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=229'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup variant='secondary' size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-dos-donts-whys-of-resume-writing-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-dos-donts-whys-of-resume-writing-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-dos-donts-whys-of-resume-writing-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Img alt='The Over 50 Workforce' variant='top' src='../images/course-soft-skills/over-50-workforce.webp' />
                  <Card.Body>
                    <Card.Title>The Over 50 Workforce</Card.Title>
                    <Card.Text>
                      This course is designed to increase the over 50
                      jobseeker's confidence when looking for a new job or
                      re-entering the workforce.
                    </Card.Text>
                    <Button
                      target='_blank'
                      href='https://tqa.pcghuslms.com/course/view.php?id=248'
                      type='button'
                      className={classNames(buttonStyles.button)}
                    >
                      View Course
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <p className='h6'>Downloads</p>
                    <ButtonGroup size='sm'>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-over-50-workforce-slides.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Slides
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-over-50-workforce-handout.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Handout
                      </Button>
                      <Button
                        type='button'
                        target='_blank'
                        href='../modules/module-the-over-50-workforce-script.pdf'
                        className={classNames(buttonStyles.button)}
                      >
                        Script
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Container>
      <div className='footer'>
        <Footer/>
      </div>
    </main>
  );
}
